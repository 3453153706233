<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Permission List</h4>
          <div class="text-right mb-3">
            <a
              href="/permissions/permission-management/create-permissions"
              class="btn btn-primary"
              >Añadir Permisio</a
            >
          </div>
          <div>
            <table
              id="myTable"
              class="table table-responsive"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Entorno</th>
                  <th>F. creación</th>
                  <th style="width: 10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in permissions" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.guardName }}</td>
                  <td>{{ item.createdAt }}</td>
                  <td>
                    <base-button
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                      @click="modals.edit = true"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <base-button
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                      @click="deletePermission(item.id)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <modal
              :show.sync="modals.edit"
              id="searchModal"
              :centered="false"
              :show-close="true"
            >
              <template slot="header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Editar Usuario
                </h5>
              </template>
              <div>...</div>
              <template slot="footer">
                <base-button type="secondary" @click="modals.edit = false"
                  >Cerrar</base-button
                >
                <base-button type="primary">Guardar</base-button>
              </template>
            </modal>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import Modal from "@/components/Modal";

//import "datatables.net-fixedheader-bs5";
import $ from "jquery";
const tableColumns = ["Name", "Email", "Created At"];
//https://datatables.net/download/npm
//https://datatables.net/extensions/fixedheader/examples/integration/responsive-bootstrap.html

export default {
  components: {
    BaseTable,
    DataTable,
    Modal,
  },

  data() {
    return {
      permissions: [],
      loadingvar: false,
      table: null,
      modals: {
        edit: false,
      },
    };
  },
  watch: {
    permissions() {
      this.table.destroy();
      // data used in the table
      this.$nextTick(() => {
        // wait until data fully updated before re-render new DOM
        this.table = new DataTable("#myTable", {
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getList();
  },
  methods: {
    async getList() {
      await this.$store.dispatch("permissions/list");
      this.permissions = await this.$store.getters["permissions/list"];
    },
    deletePermission(id) {
      console.log(id);
    },
    editPermission(id) {
      console.log(id);
    },
  },
};
</script>
