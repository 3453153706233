<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/css/bootstrap-select.min.css");
</style>
<style>
.bootstrap-select.btn-group .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
</style>
<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Editar Usuario</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <base-input
                class="col-md-3"
                type="text"
                label="Nombres"
                placeholder="Nombres"
                v-model="form.data.first_name"
              />
              <base-input
                class="col-md-3"
                type="text"
                label="Apellidos"
                placeholder="Apellidos"
                v-model="form.data.last_name"
              />
              <base-input
                class="col-md-3"
                type="number"
                label="Celular"
                placeholder="Celular"
                v-model="form.data.contact_number"
              />
              <base-input class="col-md-3" label="Rol">
                <select
                  id="inputRole"
                  class="form-control"
                  v-model="form.data.role"
                >
                  <option
                    v-for="item in roles"
                    :value="item.name"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="form-row">
              <div class="btn-group bootstrap-select col-md-3 mb-4">
                <label class="control-label"> Plan </label>
                <select
                  class="selectpicker col-md-12 pl-0 pr-0"
                  data-style="select-with-transition btn-simple"
                  multiple=""
                  title="-"
                  data-gtm-form-interact-field-id="0"
                  data-icon-base="fas"
                  data-tick-icon="fa-check"
                  v-model="select"
                >
                  <option v-for="item in plans" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <base-input
                class="col-md-3"
                type="email"
                label="Email"
                placeholder="Email"
                v-model="email"
                disabled
              />
              <div class="form-row col-md-6">
                <base-input
                  class="col-md-6 col-sm-6"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  v-model="password"
                  disabled
                />
                <button
                  class="btn btn-primary btn-fab btn-icon btn-round mt-4 cold-md-2"
                  @click="openModal()"
                  type="button"
                >
                  <i class="tim-icons icon-pencil"></i>
                </button>
              </div>
            </div>
            <base-button type="primary" native-type="Submit"
              >Guardar</base-button
            >
          </form>
        </card>
      </div>
    </div>
    <modal
      :show.sync="modals.update"
      id="modal"
      :centered="false"
      :show-close="true"
    >
      <template slot="header">
        <h5 class="modal-title">Cambiar contraseña xx</h5>
      </template>
      <div class="form-row">
        <base-input
          class="col-md-6 col-sm-6"
          type="password"
          label="Contraseña"
          placeholder="Contraseña"
          v-model="modals.password"
        />
        <base-input
          class="col-md-6 col-sm-6"
          type="password"
          label="Confirmar Contraseña"
          placeholder="Contraseña"
          v-model="modals.password_confirmation"
        />
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.update = false"
          >Cancelar</base-button
        >
        <base-button type="primary" @click="updatePassword()"
          >Actualizar</base-button
        >
      </template>
    </modal>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/js/bootstrap-select.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/js/i18n/defaults-fr_FR.min.js"></script>

<script>
import $ from "jquery";

require("bootstrap");
require("bootstrap-select");
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      roles: [],
      users: [],
      plans: [],
      select: [],
      password: "******",
      email: "",
      form: {
        data: {
          first_name: "",
          last_name: "",
          contact_number: "",
          role: "user",
          plan_ids: [],
          id: 0,
        },
      },
      modals: {
        update: false,
        password: "",
        password_confirmation: "",
        id: 0,
      },
    };
  },
  async mounted() {
    await this.getList();
    await this.loadUser();
    await this.getPlans();
    $(this.$refs.select).selectpicker();
  },
  watch: {
    select: function (val) {
      this.form.data.plan_ids = Array.from(this.select);
    },
  },
  updated: function () {
    this.$nextTick(function () {
      $(".selectpicker").selectpicker("refresh");
    });
  },
  methods: {
    async updatePassword() {
      this.modals.id = this.$route.query.id;
      try {
        await this.$store.dispatch("authmodule/update", this.modals);
        this.$notify({
          type: "success",
          message: "La contraseña fue actualizada.",
        });
        this.modals.update = false;
      } catch (error) {
        console.log(error);
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
    async openModal() {
      this.modals.update = true;
    },
    async handleSubmit() {
      this.form.data.id = this.$route.query.id;
      try {
        await this.$store.dispatch("users/update", this.form.data);
        this.$notify({
          type: "success",
          message: "El usuario fue actualizado.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },

    async getList() {
      await this.$store.dispatch("roles/list");
      this.roles = await this.$store.getters["roles/list"];
    },

    async getPlans() {
      await this.$store.dispatch("plans/list");
      this.plans = await this.$store.getters["plans/list"];
    },

    async loadUser() {
      await this.$store.dispatch("users/get", this.$route.query.id);
      this.users = await this.$store.getters["users/user"].user;
      this.form.data.first_name = this.users.firstName;
      this.form.data.last_name = this.users.lastName;
      this.form.data.contact_number = this.users.contactNumber;
      this.form.data.role = this.users.roles[0].name;
      this.email = this.users.email;
      var items = [];
      this.users.planUsers.forEach((element) => {
        items.push(element.planId);
      });
      this.form.data.plan_ids = items;
      this.select = items;
    },
  },
};
</script>
