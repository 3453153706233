<script src="//cdnjs.cloudflare.com/ajax/libs/jasny-bootstrap/4.0.0/css/jasny-bootstrap.min.css"></script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>

<style>
.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  direction: ltr;
}

.fileinput {
  display: inline-block;
  margin-bottom: 9px;
}

.fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 250px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

.fileinput .thumbnail.img-circle {
  border-radius: 50%;
  max-width: 100px;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group {
  display: table;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}

.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new.input-group .btn-group-sm > .btn-file.btn,
.fileinput-new .input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-group-sm > .btn-file.btn {
  border-radius: 0 3px 3px 0;
}

.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new.input-group .btn-group-lg > .btn-file.btn,
.fileinput-new .input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-group-lg > .btn-file.btn {
  border-radius: 0 6px 6px 0;
}

.form-group.has-warning .fileinput .fileinput-preview {
  color: #ff8d72;
}

.form-group.has-warning .fileinput .thumbnail {
  border-color: #ff8d72;
}

.form-group.has-error .fileinput .fileinput-preview {
  color: #fd5d93;
}

.form-group.has-error .fileinput .thumbnail {
  border-color: #fd5d93;
}

.form-group.has-success .fileinput .fileinput-preview {
  color: #00f2c3;
}

.form-group.has-success .fileinput .thumbnail {
  border-color: #00f2c3;
}

.input-group-addon:not(:first-child) {
  border-left: 0;
}

.thumbnail {
  border: 0;
  border-radius: 3px;
  padding: 0;
}

.bootstrap-tagsinput {
  display: inline-block;
  padding: 4px 6px;
  max-width: 100%;
  line-height: 22px;
}

.bootstrap-tagsinput input {
  border: none;
  color: #9a9a9a;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  width: 74px;
  max-width: inherit;
}

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}

.bootstrap-tagsinput.form-control input:-ms-input-placeholder,
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}

.bootstrap-tagsinput .badge {
  cursor: pointer;
  margin: 5px 3px 5px 0;
  position: relative;
  padding: 3px 8px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.5em;
  padding-left: 0.8em;
}

.bootstrap-tagsinput .badge:hover {
  padding-right: 22px;
}

.bootstrap-tagsinput .badge:hover [data-role="remove"] {
  opacity: 1;
  padding-right: 4px;
}

.bootstrap-tagsinput .badge [data-role="remove"] {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0;
  opacity: 0;
  background-color: transparent;
}

.bootstrap-tagsinput .badge [data-role="remove"]:after {
  font-family: nucleo;
  content: "\ea48";
  padding: 0 2px;
}
</style>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/css/bootstrap-select.min.css");
</style>
<style>
.bootstrap-select.btn-group .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
</style>

<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Crear Láminas</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <base-input
                class="col-md-2"
                type="text"
                label="Código"
                placeholder="0001"
                v-model="form.data.code"
              />
              <base-input
                class="col-md-3"
                type="text"
                label="Nombre"
                placeholder="Nombre"
                v-model="form.data.name"
              />

              <div class="btn-group bootstrap-select col-md-3 mb-4">
                <label class="control-label"> Tags </label>
                <select
                  class="selectpicker col-md-12 pl-0 pr-0"
                  data-style="select-with-transition btn-simple"
                  multiple=""
                  title="-"
                  data-gtm-form-interact-field-id="0"
                  data-icon-base="fas"
                  data-tick-icon="fa-check"
                  v-model="selectTags"
                  data-live-search="true"
                >
                  <option v-for="item in tags" :value="item.id">
                    {{ item.description }}
                  </option>
                </select>
              </div>

              <div class="btn-group bootstrap-select col-md-3 mb-4">
                <label class="control-label"> Categorias </label>
                <select
                  class="selectpicker col-md-12 pl-0 pr-0"
                  data-style="select-with-transition btn-simple"
                  multiple=""
                  title="-"
                  data-gtm-form-interact-field-id="0"
                  data-icon-base="fas"
                  data-tick-icon="fa-check"
                  v-model="selectCategories"
                  data-live-search="true"
                >
                  <option v-for="item in categories" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <div class="form-check form-check-inline col-md-2 mb-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="form.data.isMostSeen"
                  />
                  Mas visto
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check form-check-inline col-md-2 mb-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="form.data.isRecommended"
                  />
                  Recomendado
                  <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
            <div class="form-row">
              <div
                class="fileinput fileinput-new text-center col-md-4"
                data-provides="fileinput"
              >
                <span style="display: block">Tira</span>
                <div class="fileinput-new thumbnail" id="file-default">
                  <img :src="tira" alt="..." />
                </div>
                <div
                  class="fileinput-preview fileinput-exists thumbnail"
                  id="file-preview"
                >
                  <img :src="tira" alt="..." />
                </div>
                <div style="display: flex; place-content: center">
                  <span class="btn btn-raised btn-round btn-primary btn-file">
                    <span class="fileinput-new" id="file-select"
                      >Select image</span
                    >
                    <span class="fileinput-exists" id="file-change"
                      >Change</span
                    >
                    <input type="file" name="..." @change="previewFiles" />
                  </span>
                  <a
                    href="#"
                    class="btn btn-danger btn-round fileinput-exists"
                    data-dismiss="fileinput"
                    id="file-remove"
                    v-on:click="removeFile"
                  >
                    <i class="fa fa-times"></i> Remove</a
                  >
                </div>
              </div>
              <div
                class="fileinput fileinput-new text-center col-md-4"
                data-provides="fileinput"
              >
                <span style="display: block">Retira</span>
                <div class="fileinput-new thumbnail" id="file-default-2">
                  <img :src="retira" alt="..." />
                </div>
                <div
                  class="fileinput-preview fileinput-exists thumbnail"
                  id="file-preview-2"
                >
                  <img :src="retira" alt="..." />
                </div>
                <div style="display: flex; place-content: center">
                  <span class="btn btn-raised btn-round btn-primary btn-file">
                    <span class="fileinput-new" id="file-select-2"
                      >Select image</span
                    >
                    <span class="fileinput-exists" id="file-change-2"
                      >Change</span
                    >
                    <input type="file" name="..." @change="previewFiles2" />
                  </span>
                  <a
                    href="#"
                    class="btn btn-danger btn-round fileinput-exists"
                    data-dismiss="fileinput"
                    id="file-remove-2"
                    v-on:click="removeFile2"
                  >
                    <i class="fa fa-times"></i> Remove</a
                  >
                </div>
              </div>
              <!--<div class="form-row" style="display: block">
                <div
                  class="form-check form-check-inline col-md-6"
                  style="display: inline-block"
                >
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="form.data.isHorizontal"
                    />
                    ¿Tira es vertical?
                    <span class="form-check-sign"></span>
                  </label>
                </div>
                <div
                  class="form-check form-check-inline col-md-6"
                  style="display: inline-block"
                >
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="form.data.isHorizontalBack"
                    />
                    ¿Retira es vertical?
                    <span class="form-check-sign"></span>
                  </label>
                </div>
              </div>-->
            </div>
            <div class="form-row">
              <div class="col-md-12 mt-2">
                <div v-if="editor">
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleBold().run()"
                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                    :class="{
                      'is-active': editor.isActive('bold'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    bold
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleItalic().run()"
                    :disabled="
                      !editor.can().chain().focus().toggleItalic().run()
                    "
                    :class="{
                      'is-active': editor.isActive('italic'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    italic
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleStrike().run()"
                    :disabled="
                      !editor.can().chain().focus().toggleStrike().run()
                    "
                    :class="{
                      'is-active': editor.isActive('strike'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    strike
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleCode().run()"
                    :disabled="!editor.can().chain().focus().toggleCode().run()"
                    :class="{
                      'is-active': editor.isActive('code'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    code
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().unsetAllMarks().run()"
                    :class="{ 'btn btn-info btn-sm': 'btn btn-info btn-sm' }"
                  >
                    clear marks
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().clearNodes().run()"
                    :class="{ 'btn btn-info btn-sm': 'btn btn-info btn-sm' }"
                  >
                    clear nodes
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().setParagraph().run()"
                    :class="{
                      'is-active': editor.isActive('paragraph'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    paragraph
                  </button>
                  <button
                    type="button"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 1 }).run()
                    "
                    :class="{
                      'is-active': editor.isActive('heading', { level: 1 }),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    h1
                  </button>
                  <button
                    type="button"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 2 }).run()
                    "
                    :class="{
                      'is-active': editor.isActive('heading', { level: 2 }),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    h2
                  </button>
                  <button
                    type="button"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 3 }).run()
                    "
                    :class="{
                      'is-active': editor.isActive('heading', { level: 3 }),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    h3
                  </button>
                  <button
                    type="button"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 4 }).run()
                    "
                    :class="{
                      'is-active': editor.isActive('heading', { level: 4 }),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    h4
                  </button>
                  <button
                    type="button"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 5 }).run()
                    "
                    :class="{
                      'is-active': editor.isActive('heading', { level: 5 }),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    h5
                  </button>
                  <button
                    type="button"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 6 }).run()
                    "
                    :class="{
                      'is-active': editor.isActive('heading', { level: 6 }),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    h6
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{
                      'is-active': editor.isActive('bulletList'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    bullet list
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="{
                      'is-active': editor.isActive('orderedList'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    ordered list
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleCodeBlock().run()"
                    :class="{
                      'is-active': editor.isActive('codeBlock'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    code block
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().toggleBlockquote().run()"
                    :class="{
                      'is-active': editor.isActive('blockquote'),
                      'btn btn-info btn-sm': 'btn btn-info btn-sm',
                    }"
                  >
                    blockquote
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().setHorizontalRule().run()"
                    :class="{ 'btn btn-info btn-sm': 'btn btn-info btn-sm' }"
                  >
                    horizontal rule
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().setHardBreak().run()"
                    :class="{ 'btn btn-info btn-sm': 'btn btn-info btn-sm' }"
                  >
                    hard break
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().undo().run()"
                    :disabled="!editor.can().chain().focus().undo().run()"
                    :class="{ 'btn btn-info btn-sm': 'btn btn-info btn-sm' }"
                  >
                    undo
                  </button>
                  <button
                    type="button"
                    @click="editor.chain().focus().redo().run()"
                    :disabled="!editor.can().chain().focus().redo().run()"
                    :class="{ 'btn btn-info btn-sm': 'btn btn-info btn-sm' }"
                  >
                    redo
                  </button>
                </div>
                <editor-content :editor="editor" />
              </div>
            </div>
            <base-button
              type="primary"
              native-type="Submit"
              class="float-right"
            >
              Guardar
            </base-button>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script src="//cdnjs.cloudflare.com/ajax/libs/jasny-bootstrap/4.0.0/js/jasny-bootstrap.min.js"></script>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/js/bootstrap-select.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/js/i18n/defaults-fr_FR.min.js"></script>

<script>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-2";

import $ from "jquery";
require("bootstrap");
require("bootstrap-select");

export default {
  components: { EditorContent },

  data() {
    return {
      editor: null,
      tira: "/img/empty-image.png",
      retira: "/img/empty-image.png",
      tags: [],
      selectTags: [],
      selectCategories: [],
      categories: [],
      form: {
        data: {
          code: "",
          name: "",
          isMostSeen: false,
          isRecommended: false,
          //isHorizontal: false,
          //isHorizontalBack: false,
          description: "",
          front: "",
          back: "",
          tag_ids: [],
          category_ids: [],
        },
      },
    };
  },
  watch: {
    selectTags: function (val) {
      this.form.data.tag_ids = Array.from(this.selectTags);
    },
    selectCategories: function (val) {
      this.form.data.category_ids = Array.from(this.selectCategories);
    },
  },
  async mounted() {
    document.getElementById("file-preview").style.display = "inline-block";
    document.getElementById("file-default").style.display = "none";
    document.getElementById("file-preview-2").style.display = "inline-block";
    document.getElementById("file-default-2").style.display = "none";
    this.editor = new Editor({
      extensions: [StarterKit],
      content: ``,
      /*content: `
        <h2>
          Descripción
        </h2>
        <p>Láminas</p>
      `,*/
    });
    await this.getTags();
    await this.getCategories();
    $(this.$refs.selectTags).selectpicker();
    $(this.$refs.selectCategories).selectpicker();
  },
  updated: function () {
    this.$nextTick(function () {
      $(".selectpicker").selectpicker("refresh");
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    async getTags() {
      await this.$store.dispatch("tags/list");
      this.tags = await this.$store.getters["tags/list"];
    },
    async getCategories() {
      await this.$store.dispatch("categories/list");
      this.categories = await this.$store.getters["categories/list"];
    },
    async handleSubmit() {
      this.form.data.description = this.editor.getHTML();

      const form = new FormData();
      form.append("code", this.form.data.code);
      form.append("name", this.form.data.name);
      form.append("is_most_seen", this.form.data.isMostSeen ? 1 : 0);
      form.append("is_recommended", this.form.data.isRecommended ? 1 : 0);
      //form.append("is_horizontal", this.form.data.isHorizontal ? 1 : 0);
      /*form.append(
        "is_horizontal_back",
        this.form.data.isHorizontalBack ? 1 : 0
      );*/
      form.append("description", this.form.data.description);

      if (!!this.form.data.front) {
        form.append("front", this.form.data.front);
      }

      if (!!this.form.data.back) {
        form.append("back", this.form.data.back);
      }

      for (var i = 0; i < this.selectCategories.length; i++) {
        form.append("category_ids[]", this.selectCategories[i]);
      }

      for (var i = 0; i < this.selectTags.length; i++) {
        form.append("tag_ids[]", this.selectTags[i]);
      }

      try {
        await this.$store.dispatch("sheets/add", form);
        this.$notify({
          type: "success",
          message: "La lámina fue creada.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },

    previewFiles(event) {
      this.tira = URL.createObjectURL(event.target.files[0]);
      console.log(event.target.files[0]);
      this.form.data.front = event.target.files[0];
      console.log(this.form.data.front);
      document.getElementById("file-change").style.display = "block";
      document.getElementById("file-select").style.display = "none";
      document.getElementById("file-remove").style.display = "block";
    },
    removeFile() {
      this.tira = "/img/empty-image.png";
      this.form.data.front = "";
      document.getElementById("file-select").style.display = "block";
      document.getElementById("file-change").style.display = "none";
      document.getElementById("file-remove").style.display = "none";
    },

    previewFiles2(event) {
      this.retira = URL.createObjectURL(event.target.files[0]);
      this.form.data.back = event.target.files[0];
      document.getElementById("file-change-2").style.display = "block";
      document.getElementById("file-select-2").style.display = "none";
      document.getElementById("file-remove-2").style.display = "block";
    },
    removeFile2() {
      this.retira = "/img/empty-image.png";
      this.form.data.back = "";
      document.getElementById("file-select-2").style.display = "block";
      document.getElementById("file-change-2").style.display = "none";
      document.getElementById("file-remove-2").style.display = "none";
    },
  },
};
</script>
