<style lang="scss">
$default: black;
$active: #f96332;
$inactive: #6c757d;
$text: #9d9d9d;
$text-hover: white;
$size: 0.5rem;
$w: 60px;
$h: 30px;
$circle: $h - 4;
$toggle-dis: $w - $circle - 2;

.switch {
  position: relative;
  display: inline-block;
  width: $w;
  height: $h;
  border-radius: 100px;

  .circle {
    display: flex;
    align-items: center;
    width: 100%;
    height: $h - 2;
    border-radius: 100px;
    background-color: $default;

    & > span {
      font-size: $size;
      color: $text;
      padding-left: $h + 4;
    }

    &::before {
      position: absolute;
      content: "";
      height: $circle;
      width: $circle;
      left: 1px;
      top: 1px;
      border-radius: 100px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &:hover {
      background-color: lighten($default, 20);

      & > span {
        color: $text-hover;
      }
    }
  }

  .circle-on {
    background-color: $active;

    & > span {
      color: white;
    }

    &:hover {
      background-color: white;
      border: 1px $active solid;

      &::before {
        background-color: $active;
      }

      & > span {
        color: $active;
      }
    }
  }

  .circle-off {
    background-color: $inactive;

    & > span {
      color: white;
    }

    &:hover {
      background-color: white;
      border: 1px $inactive solid;

      &::before {
        background-color: $inactive;
      }

      & > span {
        color: $inactive;
      }
    }
  }

  & input {
    display: none;

    &:checked + .circle:before {
      -webkit-transform: translateX($toggle-dis);
      -ms-transform: translateX($toggle-dis);
      transform: translateX($toggle-dis);
    }
  }
}
</style>

<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Lista Clientes</h4>
          <div class="text-right mb-3">
            <a href="/clients/client-create" class="btn btn-primary"
              >Añadir Usuario</a
            >
          </div>
          <div>
            <table
              id="myTable"
              class="table table-responsive"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th style="width: 10%">Nombres</th>
                  <th>Correo</th>
                  <th>Rol</th>
                  <th>Plan</th>
                  <th>Estado</th>
                  <th>F. creación</th>
                  <th style="width: 10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in users" :key="item.tblusrocdgo">
                  <td>{{ item.firstName }} {{ item.lastName }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <span v-for="rol in item.roles" :key="rol.id">
                      {{ rol.name }}</span
                    >
                  </td>
                  <td style="width: 20%">{{ item.plans }}</td>
                  <td>
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        v-bind:class="
                          item.isActive
                            ? 'btn btn-primary'
                            : 'btn btn-primary btn-simple'
                        "
                        v-bind:disabled="item.isActive ? true : false"
                        @click="update(item.id)"
                      >
                        Activo
                      </button>
                      <button
                        v-bind:class="
                          item.isActive == false
                            ? 'btn btn-primary'
                            : 'btn btn-primary btn-simple'
                        "
                        v-bind:disabled="item.isActive == false ? true : false"
                        @click="update(item.id)"
                      >
                        Inactivo
                      </button>
                    </div>
                  </td>
                  <td style="width: 20%">{{ item.createdAt }}</td>
                  <td style="width: 10%">
                    <base-button
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                      @click="editUser(item.id)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <!--<base-button
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                      @click="modals.delete = true"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";

export default {
  components: {
    BaseTable,
    DataTable,
  },

  data() {
    return {
      users: [],
      usuario: [],
      table: null,
    };
  },
  watch: {
    users() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = new DataTable("#myTable", {
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getList();
  },
  methods: {
    async update(id) {
      await this.$store.dispatch("users/destroy", id);
      await this.getList();
    },
    async getList() {
      await this.$store.dispatch("users/filter", {
        attribute: "role",
        value: "user",
      });
      this.users = await this.$store.getters["users/list"];
    },
    async editUser(id) {
      let route = this.$router.resolve({
        path: "/clients/client-edit?id=" + id,
      });
      window.open(route.href);
    },
  },
};
</script>
