<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Lista Usuarios</h4>
          <div class="text-right mb-3">
            <a href="/users/user-create" class="btn btn-primary"
              >Añadir Usuario</a
            >
          </div>
          <div>
            <table
              id="myTable"
              class="table table-responsive"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th style="width: 100%">Nombres</th>
                  <th style="width: 100%">Correo</th>
                  <th style="width: 100%">Rol</th>
                  <th style="width: 100%">Plan</th>
                  <th style="width: 100%">Estado</th>
                  <th>F. creación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in users" :key="item.id">
                  <td>{{ item.firstName }} {{ item.lastName }}</td>
                  <td>{{ item.email }}</td>
                  <td style="width: 15%">
                    <span v-for="rol in item.roles" :key="rol.id">
                      {{ rol.name }}</span
                    >
                  </td>
                  <td style="width: 20%">{{ item.plans }}</td>
                  <td>
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        v-bind:class="
                          item.isActive
                            ? 'btn btn-primary'
                            : 'btn btn-primary btn-simple'
                        "
                        v-bind:disabled="item.isActive ? true : false"
                        @click="update(item.id)"
                      >
                        Activo
                      </button>
                      <button
                        v-if="email != item.email"
                        v-bind:class="
                          item.isActive == false
                            ? 'btn btn-primary'
                            : 'btn btn-primary btn-simple'
                        "
                        v-bind:disabled="item.isActive == false ? true : false"
                        @click="update(item.id)"
                      >
                        Inactivo
                      </button>
                    </div>
                  </td>
                  <td style="width: 20%">{{ item.createdAt }}</td>
                  <td style="width: 10%">
                    <base-button
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                      @click="editUser(item.id)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <!--<base-button
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                      @click="modalDeleteUser(item.id)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
    <!--<modal
      :show.sync="modals.delete"
      id="deleteModal"
      :centered="false"
      :show-close="true"
    >
      <template slot="header">
        <h5 class="modal-title">Eliminar Usuario</h5>
      </template>
      <div>¿Está seguro de eliminar el usuario?</div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.delete = false"
          >Cancelar</base-button
        >
        <base-button type="primary" @click="deleteUser()">Eliminar</base-button>
      </template>
    </modal>-->
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import Modal from "@/components/Modal";

//import "datatables.net-fixedheader-bs5";
import $ from "jquery";
const tableColumns = ["Name", "Email", "Created At"];
//https://datatables.net/download/npm
//https://datatables.net/extensions/fixedheader/examples/integration/responsive-bootstrap.html

export default {
  components: {
    BaseTable,
    DataTable,
    Modal,
  },

  data() {
    return {
      users: [],
      usuario: [],
      loadingvar: false,
      table: null,
      email: "",
      /*modals: {
        id: 0,
        edit: false,
        delete: false,
        editar: {
          nombres: "",
          apellidos: "",
          email: "",
          rol: 0,
          roles: [],
        },
      },*/
    };
  },
  watch: {
    users() {
      this.table.destroy();
      // data used in the table
      this.$nextTick(() => {
        // wait until data fully updated before re-render new DOM
        this.table = new DataTable("#myTable", {
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.email = localStorage.getItem("vue-authenticate.email");
    this.table = new DataTable("#myTable", {
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getList();
  },
  methods: {
    async update(id) {
      await this.$store.dispatch("users/destroy", id);
      await this.getList();
    },
    async getList() {
      await this.$store.dispatch("users/filter", {
        attribute: "role",
        value: "super-admin,admin",
      });
      this.users = await this.$store.getters["users/list"];
    },
    async editUser(id) {
      let route = this.$router.resolve({
        path: "/users/user-edit?id=" + id,
      });
      window.open(route.href);
    },
  },
};
</script>
