<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/css/bootstrap-select.min.css");
</style>
<style>
.bootstrap-select.btn-group .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
</style>
<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Asignar Plan</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <div class="btn-group bootstrap-select col-md-3 mb-4">
                <label class="control-label"> Usuario </label>
                <select
                  class="selectpicker col-md-12 pl-0 pr-0"
                  data-style="select-with-transition btn-simple"
                  multiple=""
                  title="-"
                  data-gtm-form-interact-field-id="0"
                  data-icon-base="fas"
                  data-tick-icon="fa-check"
                  v-model="selectUsers"
                  data-live-search="true"
                >
                  <option v-for="item in users" :value="item.id">
                    {{ item.firstName }}
                  </option>
                </select>
              </div>

              <div class="btn-group bootstrap-select col-md-3 mb-4">
                <label class="control-label"> Plan </label>
                <select
                  class="selectpicker col-md-12 pl-0 pr-0"
                  data-style="select-with-transition btn-simple"
                  multiple=""
                  title="-"
                  data-gtm-form-interact-field-id="0"
                  data-icon-base="fas"
                  data-tick-icon="fa-check"
                  v-model="selectPlans"
                  data-live-search="true"
                >
                  <option v-for="item in plans" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="from-group col-md-4 mt-3" style="text-align: center">
                <base-button type="primary" native-type="Submit"
                  >Guardar</base-button
                >
              </div>
            </div>
          </form>
        </card>
      </div>
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">
            Lista de Usuarios y Planes asignados
          </h4>

          <table
            id="myTable"
            class="table table-responsive"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th style="width: 10%">Usuario</th>
                <th style="width: 10%">Plan</th>
                <th style="width: 10%">Descargas</th>
                <th style="width: 10%">Estado</th>
                <th style="width: 10%">Fecha Creación</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in planUsers" :key="item.id">
                <td>
                  {{ item.user["firstName"] }} {{ item.user["lastName"] }}
                </td>
                <td>{{ item.plan["name"] }}</td>
                <td>{{ item.download }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.createdAt }}</td>
                <td style="width: 10%">
                  <base-button
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                    @click="modals.edit = true"
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                    @click="deletePlan(item.id)"
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </td>
              </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/js/bootstrap-select.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.12.4/js/i18n/defaults-fr_FR.min.js"></script>

<script>
import $ from "jquery";

import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";

require("bootstrap");
require("bootstrap-select");

export default {
  components: { BaseTable, DataTable },

  data() {
    return {
      table: null,
      plans: [],
      users: [],
      planUsers: [],
      selectUsers: [],
      selectPlans: [],
      form: {
        data: {
          user_ids: [],
          plan_id: 1,
          plan_ids: [],
        },
      },
    };
  },
  watch: {
    planUsers() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = new DataTable("#myTable", {
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
    selectUsers: function (val) {
      this.form.data.user_ids = Array.from(this.selectUsers);
    },
    selectPlans: function (val) {
      this.form.data.plan_ids = Array.from(this.selectPlans);
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getPlanUsers();
    await this.getPlans();
    await this.getUsers();
    $(this.$refs.selectUsers).selectpicker();
    $(this.$refs.selectPlans).selectpicker();
  },
  updated: function () {
    this.$nextTick(function () {
      $(".selectpicker").selectpicker("refresh");
    });
  },
  methods: {
    async getPlanUsers() {
      await this.$store.dispatch("plan_users/include", {
        value: "user,plan",
      });
      this.planUsers = await this.$store.getters["plan_users/list"];
    },
    async getPlans() {
      await this.$store.dispatch("plans/list");
      this.plans = await this.$store.getters["plans/list"];
      this.selectPlans = this.plans;
    },
    async getUsers() {
      await this.$store.dispatch("users/list");
      this.users = await this.$store.getters["users/list"];
      this.selectUsers = this.users;
    },
    async handleSubmit() {
      try {
        await this.$store.dispatch("plan_users/add", this.form.data);
        this.$notify({
          type: "success",
          message: "El plan fue asignado.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
  },
};
</script>
